import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Container} from "react-grid-system";
import classNames from "classnames";
import {AuthContext} from "../../../../providers/auth-provider";
import {fetchData} from "../../../../store/actions/fetch-data";
import {setSingleAdventure, updateAdventure} from "../../../../store/actions/adventure-actions";
import {LanguageContext, Translation} from "../../../../languages";
import {DICT} from "../../../../languages/dictionary-names";
import {colors, font_size} from "../../../00_base/variables";
import {Button, Heading, LoaderPageBulletList, Paragraph} from "../../../01_atoms";
import {Modal} from "../../../03_organisms";
import {RouteContext} from "../../../04_layout/app-content/l-app-content";
import {AdventureCreateHeader} from "./adventure-create-header";
import {AdventureCreateFrame} from "./adventure-riddle/adventure-create-frame";
import {PaymentSuccessMessage} from "../payment/payment-success-message";
import {AdventureEmployeeFinal} from "./adventure-employee-final/adventure-employee-final";
import {selectCurrentAdventure, selectCurrentAdventureData, selectCurrentFrames} from "../../../../store/selectors";
import {SingleFrame} from "../../../admin/riddle/template/single-frame";
import Payment from "../payment";
import {ThemeContext} from "../../../../providers/theme-provider";
import "./adventure-create.scss";

export const CreateAdventureContext = React.createContext({
    adventureName: "",
    adventureDataId: null,
    incompleteActive: false,
    handleAdventureChange: null,
    openAdminFrame: null,
    setPaymentWarningActive: null,
    handlePurchaseF: null,
    paymentSuccessful: false,
    warningsActive: null,
    setWarningsActive: null,
});
const employeeBirthdayIds = [324, 330, 332];

const ScreenAdventureCreate = ({match, history}) => {
    const adventureId = match.params.id;
    const {level = 1} = match.params;
    const {userLanguage} = useContext(LanguageContext);
    const adventure = useSelector(selectCurrentAdventure);
    const adventureData = useSelector(selectCurrentAdventureData);
    const frames = useSelector(selectCurrentFrames);
    const {isLogin, isAdmin, userData} = useContext(AuthContext);
    const {setAdminInsideWebsite} = useContext(RouteContext);
    const {mode} = useContext(ThemeContext);
    const darkMode = mode === "dark";

    const [isLoading, setIsLoading] = useState(true);
    const [activeFrame, setActiveFrame] = useState(level - 1);
    const [adminFrameActive, setAdminFrameActive] = useState(-1);
    const [paymentWarningActive, setPaymentWarningActive] = useState(false);
    const [warningsActive, setWarningsActive] = useState(false);

    const {id, is_paid, adventure_name, adventuretype, passcode, language} = adventure;
    const isEnglish = language === "en" || adventuretype === 332;
    const editableFrames = frames.filter(frame => frame.editable);
    const framesLength = editableFrames?.length;
    const oneAfterLast = activeFrame >= framesLength;
    const isEmployeeBirthday = oneAfterLast && employeeBirthdayIds.some(id => id === adventure.adventuretype);
    const filterInValidFrames = ({categorydata}) => {
        return !categorydata?.isValid || categorydata?.isValid < 100;
    };
    const allValid = editableFrames?.filter(filterInValidFrames).length === 0;
    const paymentActive = framesLength > 0 && oneAfterLast && allValid && !adventure.is_paid;
    const firstInvalid = editableFrames.findIndex(({id}) => id === editableFrames.filter(filterInValidFrames)[0]?.id);

    const dispatch = useDispatch();

    const handleUserLogout = useCallback(() => {
        if (!isLogin) {
            history.push({
                pathname: "/login",
                title: "בכדי ליצור הרפתקה חדשה אנא בצע הרשמה לאתר",
                goAfterLogin: window.location.href
            })
        }
    }, [isLogin, history]);

    useEffect(() => {
        if (isLoading) {
            window.scrollTo(0,0)
        }
    }, [isLoading])
    
    const setAdventure = useCallback(() => {
        dispatch(setSingleAdventure(adventureId, () => setIsLoading(false)));
    }, [dispatch, adventureId]);

    useEffect(handleUserLogout, [handleUserLogout]);

    useEffect(() => {
        setAdventure();
        // const interval = setInterval(() => {
        //     setAdventure();
        // }, 3000);
        // return () => clearInterval(interval);
    }, [setAdventure]);

    useEffect(() => {
        const {pathname} = history.location;
        const firstDigitIndex = pathname.indexOf(pathname.match(/\d/));
        const url = `${pathname.slice(0, firstDigitIndex - 1)}/${adventureId}/${activeFrame + 1}`
        history.push(url);
        if (framesLength) {
            if (oneAfterLast && !paymentActive) {
                setActiveFrame(firstInvalid >= 0 ? firstInvalid : framesLength);
                setWarningsActive(firstInvalid >= 0);
            }
        }
    }, [activeFrame, adventureId, firstInvalid, framesLength, history, oneAfterLast, paymentActive]);

    useEffect(() => {
        setAdminInsideWebsite(adminFrameActive);
    }, [adminFrameActive, setAdminInsideWebsite]);

    const handleAdventureChange = (name, value) => {
        dispatch(updateAdventure({...adventure, [name]: value}))
    };

    const FRAMES_STRUCTURE = (
        editableFrames?.map((frame, index) => {
            return <AdventureCreateFrame frame={frame}
                                         key={frame.id}
                                         index={index}
                                         setAdminFrameActive={() => setAdminFrameActive(index)}
                                         isLastRiddle={activeFrame === framesLength - 1}
                                         setActiveRiddle={setActiveFrame}
                                         isActive={activeFrame === index}/>
        })
    );

    const [paymentSuccessful, setPaymentSuccessful] = useState(adventure?.is_paid);

    const handlePurchaseF = () => {
        const data = {
            full_name: adventure.json_data?.employee_name,
            adventureId: adventure.id,
            email: adventure.user_email,
            email2: adventure.json_data?.employee_email,
            passcode: adventure.passcode,
            sum: 0
        };
        fetchData(
            'post',
            'purchasef',
            data,
            success => {
                window.location.reload();
                setPaymentSuccessful(true);
            }
        );
    };

    const openAdminFrame = () => setAdminFrameActive(true);
    const closePaymentFrame = () => setPaymentWarningActive(false);
    const approvePaymentWarning = () => {
        if (isEmployeeBirthday) {
            handlePurchaseF();
            setPaymentWarningActive(false);
        } else {
            setActiveFrame(editableFrames.length);
        }
        setPaymentWarningActive(false)
    };

    if (isLoading) {
        return <Container className="create-adventure">
            <LoaderPageBulletList/>
        </Container>
    }

    if (!isAdmin && (adventure.user_email !== userData.email)) {
        history.push("/user");
    }

    const PAYMENT = (paymentActive || isEmployeeBirthday) &&
        <div className={classNames("create-adventure-payment", {active: paymentActive})}>
            {isEmployeeBirthday ? <AdventureEmployeeFinal/> : <Payment adventureId={id} hideTitle={true}/>}
        </div>;

    const GO_TO_PAYMENT = !(paymentActive || isEmployeeBirthday) && (
        <Button background={colors[allValid ? "select" : "action"]}
                block className="mx-auto my-5"
                onClick={() => setActiveFrame(editableFrames.length)}>
            {employeeBirthdayIds.some(id => id === adventure.adventuretype) ?
                <Translation id={DICT.FINISH_ORDER}/> :
                <Translation id={DICT.GO_TO_PAYMENT}/>
            }
        </Button>);

    const ADMIN_DETAILS = isAdmin &&
        <div style={{textAlign: "center", marginTop: "20px"}}>
            <a href={`/${userLanguage}/admin/reservation/${id}`}
               rel="noopener noreferrer"
               target={"_blank"} style={{fontSize: font_size.sm, color: "#000"}}>Create</a>
            <p style={{fontSize: "20px"}}>Passcode: <span
                style={{fontWeight: "bold"}}>{passcode}</span></p>
        </div>;

    const adventureProvider = {
        adventure_name,
        handleAdventureChange,
        openAdminFrame,
        handlePurchaseF,
        paymentSuccessful,
        setActiveRiddle: setActiveFrame,
        warningsActive: warningsActive,
        setWarningsActive:setWarningsActive,
        setPaymentWarningActive: () => setPaymentWarningActive(true)
    };

    const ADMIN_ACTIVE_FRAME =  <div className={classNames("create-adventure-admin-frame", {active: adminFrameActive >= 0})}>
        {adminFrameActive >= 0 && <SingleFrame frame={editableFrames[activeFrame]}
                                               handleCloseFrame={() => setAdminFrameActive(-1)}/>}
    </div>;

    const PAYMENT_WARNING = paymentWarningActive &&
        <Modal isActive={'active'}
               hideTrigger={true}>
            <div className="create-adventure-payment-warning">
                {isEnglish ?
                    <Heading tag={"h4"} style={{marginBottom: "5px"}}>

                        <span style={{color: "red"}}>❤</span> Attention <span style={{color: "red"}}>❤</span>
                    </Heading>
                    :
                    <Heading tag={"h4"} style={{marginBottom: "5px"}}>
                        שימו <span style={{color: "red"}}> ❤ </span>
                    </Heading>
                }
                {isEmployeeBirthday ?
                    isEnglish ?
                        <Paragraph
                            dangerouslySetInnerHTML={`Clicking the Continue button will send an email to <span style="font-weight: bold">${adventure.json_data?.employee_email}</span> after which the adventure will not be possible to change.<br/>Would you like to continue?`}/>
                        :
                        <Paragraph
                            dangerouslySetInnerHTML={`לחיצה על כפתור המשך תשלח מייל ל <span style="font-weight: bold">${adventure.json_data?.employee_email}</span>, ולאחריה לא יהיה ניתן לשנות את ההרפתקה.<br/>האם להמשיך?`}/>
                    :
                    <Paragraph dangerouslySetInnerHTML={`לאחר התשלום אפשרויות העריכה ינעלו<br/>האם להמשיך?`}/>
                }
                <div className={"create-adventure-payment-warning-buttons"}>
                    <Button onClick={closePaymentFrame} background={colors.gray_2}><Translation id={DICT.GO_BACK}/></Button>
                    <Button onClick={approvePaymentWarning}><Translation id={DICT.CONTINUE}/></Button>
                </div>
            </div>
        </Modal>;

    const PAYMENT_SUCCESS_MESSAGE = is_paid &&
        <Modal isActive={'active'}
               hideTrigger={true}
               contentStyle={{backgroundColor: "transparent"}}
               cantClose={!isAdmin}>
            <PaymentSuccessMessage passcode={passcode} adventuretype={adventureData.adventuretype}/>
        </Modal>;

    return (
        <CreateAdventureContext.Provider value={adventureProvider}>
            {ADMIN_ACTIVE_FRAME}
            {PAYMENT_WARNING}
            {PAYMENT_SUCCESS_MESSAGE}
            <div className={classNames("create-adventure", {english: isEnglish})}>
                <Container className="create-adventure-container ">
                    <AdventureCreateHeader/>
                    {FRAMES_STRUCTURE}
                    {PAYMENT}
                    {GO_TO_PAYMENT}
                    {ADMIN_DETAILS}
                </Container>
            </div>
        </CreateAdventureContext.Provider>
    )
};

export default ScreenAdventureCreate;


//
// const getGeneralForm = (adventureTypeId) => {
//     fetchData(
//         "get",
//         `getAdventureTypeFormByAdventureDataId/${adventureTypeId}`,
//         "",
//         (response) => {
//             console.log("response", response);
//             setGeneralFormFields(response);
//         }
//     )
// };
