import React, {useContext, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import {MdClose, MdMoreVert, MdRemoveRedEye} from "react-icons/md";
import {selectAllCategories, selectCurrentRiddles} from "../../../../store/selectors";
import SVG_EDIT from "../../../../assets/images/svg-icons/svg-edit.svg";
import SVG_CLOSE from "../../../../assets/images/svg-icons/svg-close.svg";
import SVG_API from "../../../../assets/images/svg-icons/svg-api.svg";
import {RiddleContext} from "../../adventureType/adventure-type-sequence/adventure-type-sequence-riddle";
import {setActiveFrame, updateFrame} from "../../../../store/actions/frames-actions";
import {addFrameToRiddle, removeFrameFromRiddle} from "../../../../store/actions/riddle-actions";
import {MobileHidden, MobileLgHidden} from "../../../01_atoms/";
import {FieldText} from "../../../02_molecules";
import {DraggableHandle, Modal, ToggleIcon} from "../../../03_organisms/";
import {TableInnerMenu} from "../../../03_organisms/table";
import {SingleFrameAdvanced} from "./single-frame-advanced";

import {ScreenTool} from "../../../youdo-tools/src/components/05_screens/tool/screen-tool";
import {useEventListener} from "../../../../hooks/use-event-listener";

export const RiddleSingleTemplate = ({index, isHovering, hideHandle, frame, removeFrame, riddle_id}) => {
    const allCategories = useSelector(selectAllCategories);
    const allRiddles = useSelector(selectCurrentRiddles);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [advancedMenuOpen, setAdvancedMenuOpen] = useState(false);
    const [isRiddlesChosenActive, setIsRiddlesChosenActive] = useState(false);
    const [previewActive, setPreviewActive] = useState(false);
    const riddleId = useContext(RiddleContext).id || riddle_id;

    const dispatch = useDispatch();

    useEventListener(
        'keydown',
        ({key}) => {
            if (isHovering) {
                switch (key) {
                    case "Enter":
                        openFrame();
                        break;
                    case "Escape":
                        closeFrame();
                        break;
                    case "ArrowLeft":
                        setIsMenuOpen(!isMenuOpen);
                        break;
                    case "ArrowRight":
                        setAdvancedMenuOpen(!advancedMenuOpen);
                        break;
                    case "Shift":
                        setPreviewActive(!previewActive);
                        break;
                    case "x":
                        handleRemoveFrame();
                        break;
                    case "a":
                        open_api(frame.id);
                        break;
                    default:
                        return
                }
            }
        }
    );

    const handleChange = (name, value) => {
        dispatch(updateFrame(frame.id, name, value))
    };

    const handleRemoveFrame = () => {
        if (window.confirm("למחוק את הפריים?")) {
            dispatch(removeFrameFromRiddle(riddleId, frame.id, removeFrame));
        }
    };

    const changeFrameScene = (riddle) => {
        dispatch(
            removeFrameFromRiddle(
                riddleId,
                frame.id,
                () => {
                    dispatch(
                        addFrameToRiddle(
                            riddle.id,
                            frame.id,
                            () => window.location.reload()
                        )
                    )
                }
            )
        )
    };

    const open_api = id => window.open(`https://api.youdoadventures.com/riddlesTemplate/${id}`, "_blank");

    const toggleSideMenu = (e) => {
        e.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
    };
    const openFrame = () => {
        setIsMenuOpen(false);
        dispatch(setActiveFrame(frame.id));
    };

    const closeFrame = () => {
        dispatch(setActiveFrame(null));
    };

    const innerMenuItems = [
        {Icon: () => <img src={SVG_EDIT} alt="edit"/>, label: "עריכה", onClick: openFrame},
        {Icon: () => <img src={SVG_CLOSE} alt="remove"/>, label: "מחיקה", onClick: handleRemoveFrame},
        {
            Icon: () => <img src={SVG_CLOSE} alt="change-scene"/>,
            label: "העבר לסצנה אחרת",
            onClick: () => setIsRiddlesChosenActive(true)
        },
        {Icon: () => <img src={SVG_API} alt="api"/>, label: "API", onClick: open_api},
    ];

    const {id, title, categories = [], thumbnail, editable} = frame;
    const category = allCategories.find(cat => cat.id === categories[0]) || {};

    const frameIndex = <div className="frame-index">{index}</div>;

    const frameTitleIconHandle = (
        <div className={"frame-header-item frame-header-right"}>
            {!hideHandle && <DraggableHandle/>}
            <div className="frame-thumbnail">
                {thumbnail && <img width="100%" alt="frame thumbnail" src={thumbnail}/>}
            </div>
            <div className="frame-title">
                <FieldText changeHandler={handleChange}
                           name="title"
                           value={title}
                           controlStyle={{marginBottom: 0}}
                           inputStyle={{border: 0, borderBottom: "1px solid #000", borderRadius: 0}}
                           inputStyleFocus={{borderColor: "red"}}
                           inputStyleWithValue={{backgroundColor: "transparent"}}
                           placeholder="הכניסו את שם הפריים"/>
            </div>
        </div>
    );

    const frameToolName = <MobileHidden className="frame-header-item"><p
        className="frame-tool-name">{category.title}</p></MobileHidden>;

    const toolPassword = <MobileHidden
        className="frame-header-item">{frame.categorydata?.password || ""}</MobileHidden>;

    const isEditable = <MobileHidden className="frame-header-item">
        <p className="frame-tool-editable">{editable ? "מותאם אישית" : ""}</p>
    </MobileHidden>;

    const openFrameIcon = <button className="frame-action-button" onClick={() => setPreviewActive(true)}>
        <MdRemoveRedEye size={18}/></button>;

    const removeFrameIcon = <button className="frame-action-button" onClick={handleRemoveFrame}>
        <MdClose size={18}/>
    </button>;

    const toggleAdvancedMenu = <button className="frame-action-button"
                                       onClick={() => setAdvancedMenuOpen(!advancedMenuOpen)}>
        <ToggleIcon size={30} isOpen={advancedMenuOpen}/>
    </button>;

    const riddlesToChoose = allRiddles.map(riddle => {
        return {
            ...riddle,
            Icon: () => <img alt={riddle.title} width={15} src={riddle.thumbnail}/>,
            label: riddle.title,
            onClick: () => changeFrameScene(riddle)
        }
    });

    const innerMenu = (
        <button className={classNames("frame-action-button", {focus: isMenuOpen})} onClick={toggleSideMenu}>
            <MdMoreVert size={18}/>
            {isMenuOpen &&
            <TableInnerMenu items={isRiddlesChosenActive ? riddlesToChoose : innerMenuItems}
                            itemId={id}/>}
        </button>
    );

    const frameActions = (
        <div className="frame-actions">
            {toggleAdvancedMenu}
            <MobileLgHidden>{openFrameIcon}</MobileLgHidden>
            <MobileLgHidden>{removeFrameIcon}</MobileLgHidden>
            {innerMenu}
        </div>);

    return (
        <>
            {previewActive &&
            <Modal isActive={"active"}
                   hideTrigger={true}
                   closePreActions={() => setPreviewActive(false)}
                   zIndex={999999}
                   contentStyle={{width: "100%", height: "98%", display: "flex"}}
                   childrenWrapperStyle={{width: "100%"}}>
                <ScreenTool toolId={frame.categories[0]}
                            toolData={{categorydata: frame.categorydata}}
                            previewMode={true}/>
            </Modal>}

            <div className={classNames("frame", {active: isHovering})} style={{zIndex: isMenuOpen ? 100 - index : 0}}>
                {frameIndex}
                <div className="frame-header">
                    {frameTitleIconHandle}
                    {frameToolName}
                    {toolPassword}
                    {isEditable}
                    {frameActions}
                </div>

            </div>
            {advancedMenuOpen &&
            <SingleFrameAdvanced {...frame} handleChange={handleChange}/>
            }
        </>
    )
}