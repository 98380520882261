import React, {useContext, useReducer, useState} from 'react';
import {colors,} from "../../../00_base/variables";
import {Circle, Heading, Image, SimpleLoader} from "../../../01_atoms"
import {IconEdit} from "../../../02_molecules/icons";
import {AuthContext} from "../../../../providers/auth-provider";
import {FieldText, FilesUploader} from "../../../02_molecules";
import {MdSave} from "react-icons/all";

const AVATAR = 'https://admin.youdoadventures.com/wp-content/uploads/2018/04/placeholder.png';
const EDIT = "EDIT";
const RESET = "RESET";

const reducer = (state, action) => {
    if (action.type === EDIT) {
        return {...state, [action.name]: action.value}
    }
    if (action.type === RESET) {
        return action.state
    }
    return state;
};

const UserDetails = () => {
    const {userData, fullName, updateUserDetails} = useContext(AuthContext);
    const {email, avatar} = userData;
    const [editMode, setEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [editableUser, dispatchEditableUser] = useReducer(reducer, userData);


    const changeHandler = (name, value) => {
        dispatchEditableUser({type: EDIT, name: name, value: value})
    };

    const handleSubmit = () => {
        setIsLoading(true);
        updateUserDetails(
            editableUser,
            () => {
                setIsLoading(false);
                setEditMode(false)
            })
    };

    const fieldProps = {
        changeHandler: changeHandler,
        controlStyle: {paddingLeft: "10px"},
        inputStyle: {maxWidth: "100px"}
    };

    const Icon = () => {
        if (isLoading) {
            return <SimpleLoader size={20}/>
        }
        if (editMode) {
            return <MdSave size={20} cursor='pointer' color={colors.gray_6} onClick={handleSubmit}/>
        }
        return <IconEdit height={20} width={13} cursor='pointer' color={colors.gray_6} onClick={() => setEditMode(!editMode)}/>
    }

    const {name, lastName} = editableUser;
    return (
        <div style={{position: 'relative'}} className={'d-flex d-md-block mb-5'}>
            <div>
                {editMode ?
                    <FilesUploader name={"avatar"}
                                   value={editableUser.avatar}
                                   changeHandler={changeHandler}
                                   previewWidth={100}
                                   triggerValue={"תמונת פרופיל"}
                                   triggerStyle={{width: "100px", height: "100px", borderRadius: "50%"}}
                                   multiple={false}/>
                    :
                    <Circle size={100} className={'m-auto m-md-0'} background={'#ffffff'}>
                        <Image src={avatar || AVATAR}/>
                    </Circle>
                }

                <div className={!editMode && 'mt-5'}>
                    <div className={'d-flex align-items-center justify-content-center justify-content-md-start'}>
                        {editMode ?
                            <div className={"d-flex"}>
                                <FieldText name={"name"} value={name}{...fieldProps}/>
                                <FieldText name={"lastName"} value={lastName} {...fieldProps}/>
                            </div>
                            :
                            <Heading tag='h4'
                                     fontWeight='bold'
                                     style={{whiteSpace: "nowrap"}}
                                     className="my-1 ml-3"
                                     value={fullName}/>
                        }
                        <Icon/>
                    </div>
                    <Heading tag={'h6'}
                             color={colors.gray_6}
                             className={'my-1'}>
                        {email}
                    </Heading>
                </div>
            </div>

        </div>
    )
}

export default UserDetails;


// const adventures = useSelector(selectUserAdventures);
//
// const new_adventures = {
//     label: 'הרפתקאות ביצירה',
//     data: adventures.filter(adv => !adv.is_published),
//     icon: 'https://admin.youdoadventures.com/wp-content/uploads/2019/05/truck.png'
// };
// const sent_adventures = {
//     label: 'הרפתקאות בדרך אליך',
//     data: adventures.filter(adv => adv.is_sent && !adv.is_published),
//     icon: 'https://admin.youdoadventures.com/wp-content/uploads/2019/05/truck.png'
// };
// const published_adventures = {
//     label: 'הרפתקאות אצלכם',
//     data: adventures.filter(adv => adv.is_published),
//     icon: 'https://admin.youdoadventures.com/wp-content/uploads/2019/05/truck.png'
// };
// const allAdventureTypes = [new_adventures, sent_adventures, published_adventures];

//
// {/*<Col md={8} lg={9}*/}
// {/*     className={'d-flex justify-content-center justify-content-md-start d-none'}*/}
// {/*     style={{display:"none"}}> */}
// {/*    {allAdventureTypes.map((adv, i) => {*/}
// {/*        return (*/}
// {/*            <Element key={i}*/}
// {/*                     width={'14rem'}*/}
// {/*                     className={'mr-md-5 position-relative'}>*/}
// {/*                <Element position={'relative'}*/}
// {/*                         className={'mx-auto mx-md-0 '}*/}
// {/*                         width={107}>*/}
// {/*                    <Circle size={107}*/}
// {/*                            mobileSize={'74px'}*/}
// {/*                            background={colors.gray_pink}>*/}
// {/*                        <img src={adv.icon} width={80}/>*/}
// {/*                    </Circle>*/}
// {/*                    <MobileOnly>*/}
// {/*                        <Circle size={27}*/}
// {/*                                background={'#fff'}*/}
// {/*                                color={'#000'}*/}
// {/*                                className={'position-absolute'}*/}
// {/*                                right={0}*/}
// {/*                                bottom={'0'}>*/}
// {/*                            {adv.data.length}*/}
// {/*                        </Circle>*/}
// {/*                    </MobileOnly>*/}
// {/*                </Element>*/}
//
// {/*                <HBox marginTop={gutters.sm}>*/}
// {/*                    <MobileHidden>*/}
// {/*                        <Circle size={27}*/}
// {/*                                background={'#fff'}*/}
// {/*                                color={'#000'}>*/}
// {/*                            {i}*/}
// {/*                        </Circle>*/}
// {/*                    </MobileHidden>*/}
// {/*                    <Heading tag={'h6'}*/}
// {/*                             color={colors.gray_6}*/}
// {/*                             className={'pr-md-3'}*/}
// {/*                             style={{flex: 1}}>*/}
// {/*                        {adv.label}*/}
// {/*                    </Heading>*/}
// {/*                </HBox>*/}
// {/*            </Element>*/}
// {/*        )*/}
// {/*    })}*/}
// {/*</Col>*/}