import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    MdAdd,
    MdAddCircleOutline,
    MdArrowDropDown,
    MdArrowDropUp,
    MdCameraAlt,
    MdClose,
    MdMoreVert
} from "react-icons/md";
import classNames from "classnames";
import arrayMove from "array-move";
import {
    removeFrameFromRiddle,
    reorderRiddleFrames,
    setActiveRiddle,
    updateRiddle
} from "../../../../store/actions/riddle-actions";
import {createFrame} from "../../../../store/actions/frames-actions";
import {MobileLgHidden, SimpleLoader} from "../../../01_atoms";
import {FieldText, FilesUploader, ToolTip} from "../../../02_molecules";
import {TableInnerMenu} from "../../../03_organisms/table";
import {DraggableHandle} from "../../../03_organisms";
import {RiddleTemplates} from "../../riddle/template/riddle-templates";

export const RiddleContext = React.createContext({id: null});

export const RiddleRow = (
    {
        riddle,
        frames,
        menuActive,
        indexStart,
        setActiveRiddleMenu,
        hoveringFrame,
        removeRiddle
    }) => {
    const riddleId = riddle.id;

    const [isOpen, setIsOpen] = useState(true);
    const [newFrameLoading, setNewFrameLoading] = useState(false);
    const [riddleError, setRiddleError] = useState(false);

    const dispatch = useDispatch();

    const open_api = id => window.open(`https://api.youdoadventures.com/riddles/${id}`, '_blank');

    const handleChange = (name, value) => {
        dispatch(updateRiddle({...riddle, [name]: value}));
    };

    useEffect(() => {
        if (riddleError) {
            setTimeout(() => {
                setRiddleError(false)
            }, 3000)
        }
    }, [riddleError]);

    const addRiddleFrame = () => {
        setNewFrameLoading(true);
        dispatch(createFrame({title: "פריים חדש"}, riddleId, (newId) => setNewFrameLoading(false)))
    };

    const removeRiddleFrame = (frameId) => {
        if (window.confirm("למחוק את הפריים מהסצנה?")) {
            dispatch(removeFrameFromRiddle(riddleId, frameId));
        }
    };

    const reorderFrames = ({oldIndex, newIndex}) => {
        const updatedFrames = arrayMove(frames, oldIndex, newIndex);
        dispatch(reorderRiddleFrames(riddleId, updatedFrames.map(frame => frame.id)));
    };

    if (!riddle) {
        return <></>
    }


    const innerMenuItems = [
        {Icon: MdMoreVert, label: "עריכה", onClick: (id) => dispatch(setActiveRiddle(id))},
        {Icon: MdClose, label: "מחיקה", onClick: (id) => removeRiddle(id)},
        {Icon: MdAdd, label: "הוסף פריים", onClick: addRiddleFrame},
        {Icon: MdMoreVert, label: "Api", onClick: open_api}
    ];


    const IconDropdown = isOpen ? MdArrowDropUp : MdArrowDropDown;
    const {thumbnail, riddlestemplates, templatedata = {}, title, riddle_name, id} = riddle;
    const {thumbnail_correct} = templatedata || {};
    const SCENE_ERROR = (
        <div className={classNames("scene-error", {active: riddleError})}>
            <p>{"הסצנה לא נשמרה. נסו שוב."}</p>
        </div>
    );

    const HANDLE = <DraggableHandle color={"#282626"}/>;

    const SCENE_ICON = (
        <div className="scene-icon">
            <div className="d-flex align-items-center">
                <div className="px-1">
                <ToolTip tip="איקון"
                         id="riddle-thumbnail"
                         offset={{top: 0}}
                         placeholder={
                             <FilesUploader value={thumbnail}
                                            // controlStyle={{width: "50px"}}
                                            triggerStyle={{border: 0, width:"50px"}}
                                            triggerValue={<MdCameraAlt style={{cursor: "pointer"}}/>}
                                            previewWidth={50}
                                            previewHeight={50}
                                            name="thumbnail"
                                            multiple={false}
                                            changeHandler={handleChange}/>}/>
                </div>
                <div className="px-1">
                <ToolTip tip="איקון פתור"
                         id="riddle-thumbnail-active"
                         offset={{top: 0}}
                         placeholder={
                             <FilesUploader value={thumbnail_correct}
                                            // controlStyle={{width: "50px"}}
                                            triggerStyle={{border: 0, width:"50px"}}
                                            triggerValue={<MdCameraAlt style={{cursor: "pointer"}}/>}
                                            previewWidth={50}
                                            previewHeight={50}
                                            name="thumbnail_correct"
                                            multiple={false}
                                            changeHandler={(name, value) => handleChange("templatedata", {
                                                ...templatedata,
                                                [name]: value
                                            })}/>
                         }/>
                </div>
            </div>
        </div>
    );

    const SCENE_NAME = (
        <div className="scene-name">
            <FieldText name="title"
                       placeholder={"כותרת החידה"}
                       value={title}
                       controlStyle={{...controlStyle, maxWidth: '170px'}}
                       inputStyle={{...inputStyle, backgroundColor: "transparent"}}
                       changeHandler={handleChange}/>
            {frames.length > 0 &&
            <IconDropdown size={30}
                          cursor="pointer"
                          onClick={() => setIsOpen(!isOpen)}/>}
        </div>
    );

    const SCENE_ICON_TITLE = (
        <MobileLgHidden className="scene-icon-name">
            <FieldText name="riddle_name"
                       placeholder={"כותרת איקון"}
                       value={riddle_name}
                       controlStyle={{...controlStyle, maxWidth: '170px'}}
                // inputStyle={inputStyle}
                       changeHandler={handleChange}/>
        </MobileLgHidden>
    );

    const ADD_FRAME = (
        <div className="scene-actions-add" onClick={addRiddleFrame}>
            <MdAddCircleOutline size={18}/>
            <p>הוסף פריים</p>
        </div>
    );

    const REMOVE_RIDDLE = <MdClose size={18} onClick={() => removeRiddle(riddleId)}/>;

    const RIDDLE_MENU_TRIGGER = <MdMoreVert size={18}
                                            onClick={() => setActiveRiddleMenu(menuActive ? null : riddleId)}/>;

    const RIDDLE_SIDE_MENU = menuActive && <TableInnerMenu items={innerMenuItems} itemId={id}/>;

    const RIDDLE_FRAMES = riddlestemplates && (
        <div className={classNames("scene-frames-container", {open: isOpen})}>
            <RiddleTemplates frames={frames}
                             indexStart={indexStart}
                             hoveringFrame={hoveringFrame}
                             templatedata={templatedata}
                             removeRiddleFrame={removeRiddleFrame}
                             reorderFrames={reorderFrames}
                             handleChange={handleChange}/>

        </div>
    );

    return (
        <RiddleContext.Provider value={{id: riddleId}}>
            {SCENE_ERROR}
            <div className="scene-row">
                <div className="scene-details">
                    {HANDLE}
                    {SCENE_ICON}
                    {SCENE_NAME}
                    {SCENE_ICON_TITLE}
                </div>
                <div className="scene-actions">
                    <MobileLgHidden>{ADD_FRAME}</MobileLgHidden>
                    <MobileLgHidden>{REMOVE_RIDDLE}</MobileLgHidden>
                    {RIDDLE_MENU_TRIGGER}
                    {RIDDLE_SIDE_MENU}
                </div>

            </div>
            {RIDDLE_FRAMES}
            {newFrameLoading && <SimpleLoader/>}

        </RiddleContext.Provider>
    )
};

const controlStyle = {margin: 0, height: "100%"};
const inputStyle = {border: 0, height: "100%", padding: 0, fontWeight: "bold"};