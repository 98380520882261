import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {VBox} from "react-stylesheet";
import {selectActiveBlog} from "../../../../../store/selectors";
import {createBlog, setSingleBlog, updateBlog} from "../../../../../store/actions/blogs-actions";
import {Button, SimpleLoader} from "../../../../01_atoms";
import {FieldText, FieldTextArea, FilesUploader, ToolTip} from "../../../../02_molecules";
import {Table, TableContainer, TableTitle, TableWrapper, Tr} from "../../../../03_organisms/table";
import {Switcher} from "../../../../01_atoms/switch/a-switch";
import {ChooseItemLanguage} from "../../../../03_organisms/choose-item-language/choose-item-language";
import "./blog-edit.scss";

const ScreenBlogEdit = ({match}) => {
    const dispatch = useDispatch();
    const currentBlog = useSelector(selectActiveBlog);
    const [editedBlog, setEditedBlog] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const blogId = match.params.blogId;

    useEffect(() => dispatch(setSingleBlog(blogId)), [dispatch]);

    useEffect(() => {
        setEditedBlog(!_.isEmpty(currentBlog) ? currentBlog : {});

        //Because image takes time to load
        //Todo: FIX
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)

    }, [currentBlog]);

    const changeHandler = (key, value) => setEditedBlog({...editedBlog, [key]: value});

    const renderRegularInput = (label, key, type = "text", disabled = false) => {
        return (
            <Tr>
                <FieldText name={key}
                           mode={disabled && "read"}
                           value={editedBlog[key]}
                           type={type}
                           label={label}
                           changeHandler={changeHandler}
                />
            </Tr>
        )
    };
    const renderTextareaInput = (label, key) => {
        return (
            <Tr>
                <FieldTextArea wysiwyg={true}
                               toolbarHidden={false}
                               changeHandler={changeHandler}
                               label={label}
                               value={editedBlog[key]}
                               inputStyle={{fontSize: "1.6em"}}
                               name={key}/>
            </Tr>
        )
    };

    const renderImagesInput = (title, triggerValue, key) => {
        return (
            <Tr>
                <FilesUploader name={key}
                               value={editedBlog[key]}
                               multiple={false}
                               controlStyle={{marginBottom: "1.5rem"}}
                               triggerValue={triggerValue}
                               label={title}
                               changeHandler={changeHandler}
                />
            </Tr>
        )
    };

    const renderSwitcherInput = (label, key) => {
        return (
            <Tr>
                <Switcher name={key}
                          checked={editedBlog[key]}
                          label={label}
                          changeHandler={changeHandler}/>
            </Tr>
        )
    };

    const onCreate = () => {
        setIsSaving(true);
        if (_.isEmpty(blogId)) {
            dispatch(createBlog(editedBlog, onSuccess));
        } else {
            dispatch(updateBlog(editedBlog, onSuccess));
        }
    };

    const onSuccess = (res) => {
        console.log("Success - ", res);
        if (_.isEmpty(blogId)) {
            navigateToBlogs();
        } else {
            setIsSaving(false);
        }
    };

    const onError = (err) => {
        console.log("Error - ", err);
        setIsLoading(false);
        alert("שגיאה");
    };

    const renderSaveButton = () => {
        const isButtonDisable = () => {
            if (_.isEmpty(editedBlog.title)) {
                return "חסרה כותרת"
            } else if (_.isEmpty(editedBlog.description)) {
                return "חסר תיאור"
            } else if (_.isEmpty(editedBlog.main_image)) {
                return "חסרה תמונה"
            } else if (_.isEqual(editedBlog, currentBlog)) {
                return "לא בוצע שינוי"
            }
            return false;
        };

        const submitValue = isSaving ?
            <SimpleLoader size={17} color={"#fff"}/> : _.isEmpty(blogId) ? "צור בלוג" : "עדכן בלוג";

        return (
            <div className={"d-flex mb-3"}>
                {isButtonDisable() ?
                    <ToolTip tip={isButtonDisable()}
                             id={"upsert-button"}
                             placeholder={
                                 <Button onClick={onCreate}
                                         className={"submit-blog-edit-button"}
                                         value={submitValue}
                                         disabled/>
                             }/> :
                    <Button onClick={onCreate}
                            className={"submit-blog-edit-button active"}
                            value={submitValue}/>
                }
            </div>
        )
    };

    const navigateToBlogs = () => {
        window.location.pathname = "/he/admin/blogs"
    };

    if (isLoading) {
        return (
            <VBox alignItems={"center"} justifyContent={'center'} height={"100vh"}>
                <SimpleLoader/>
            </VBox>
        )
    }

    return (
        <TableWrapper className={"blog-edit-container"}>
            <TableTitle>
                <h1>{blogId ? currentBlog?.title : "צור בלוג חדש"}</h1>
            </TableTitle>
            <TableContainer className={"pb-5"}>
                <Table cellSpacing="0" cellPadding="0">
                    <div className={"d-flex justify-content-between align-items-center"}>
                        <ChooseItemLanguage changeHandler={changeHandler}
                                            label={"שפה"}
                                            value={currentBlog?.language_id}/>

                        {renderSwitcherInput("פעיל", "is_active")}
                    </div>
                    {renderSaveButton()}
                    {renderRegularInput("כותרת", "title")}
                    {renderRegularInput("תת כותרת", "subtitle")}
                    {renderTextareaInput("תיאור", "description")}
                    {renderTextareaInput("תיאור מקוצר", "description_short")}
                    {renderRegularInput("לינק", "url")}

                    {renderImagesInput("תמונה מרכזית", "העלו תמונה ראשית", "main_image")}
                    {renderImagesInput("תמונת גלריה", "העלו תמונת גלריה", "thumbnail_image")}
                </Table>
                {renderSaveButton()}
            </TableContainer>
        </TableWrapper>
    )
}

export default ScreenBlogEdit;