
import ScreenRegister from "./components/05_screens/authentication";
import ScreenUsersGallery from "./components/admin/pages/customers-gallery";
import ScreenAdventuresGallery from "./components/admin/pages/adventures-gallery";
import {SingleAdventure} from "./components/admin/adventure/single-adventure";
import {NewAdventure} from "./components/admin/adventure/new-adventure";
import ScreenAdventureTypeGallery from "./components/admin/pages/adventureType-gallery";
import CreateAdventureType from "./components/admin/adventureType";
import ScreenAdventureTemplatesGallery
    from "./components/admin/pages/adventureTemplates-gallery/screen-adventureTemplates-gallery";
import ScreenTemplatesGallery from "./components/admin/pages/templates-gallery";
import ScreenCategoriesGallery from "./components/admin/pages/categories-gallery/screen-categories-gallery";
import ScreenThemesGallery from "./components/admin/pages/themes-gallery";
import ScreenTagsGallery from "./components/admin/pages/tags-gallery";
import ScreenCouponsGallery from "./components/admin/pages/coupons-gallery";
import ScreenAdminBlog from "./components/admin/pages/blog/blog";
import ScreenBlogEdit from "./components/admin/pages/blog/blod-edit/blog-edit";

import {ScreenTranslations} from "./components/admin/pages/translations/screen-translations";
import ScreenDashboard from "./components/admin/pages/dashboard";
import ScreenLogin from "./components/05_screens/authentication/login/screen-login";
import ScreenAbout from "./components/website/pages/about/screen-about";
import ScreenProduct from "./components/website/pages/product/screen-product";
import ScreenContactUs from "./components/website/pages/contact-us/screen-contact-us";
import ScreenBlog from "./components/website/pages/blog";
import SingleBlog from "./components/website/pages/blog/single-blog";
import ScreenGallery from "./components/website/pages/gallery/screen-gallery";
import ScreenTerms from "./components/website/pages/terms/screen-terms";
import {ScreenUser} from "./components/website/pages/user/screen-user";
import RiddleStore from "./components/website/pages/riddles-store/riddles-store";
import ScreenAdventure from "./components/website/pages/adventure/screen-adventure";
import ScreenPayment from "./components/website/pages/payment";

import PaymentSuccess from "./components/website/pages/payment/payment-success";
import PaymentFail from "./components/website/pages/payment/payment-fail";
import ScreenAdventureCreate from "./components/website/pages/adventure-create/screen-adventure-create";
import {CreateSpaceAdventure} from "./components/website/pages/create-space-adventure/create-space-adventure";
import ScreenFaq from "./components/website/pages/faq";
import {AdventuresCustomizeGallery} from "./components/website/pages/adventures-gallery/adventures-customize-gallery";
import {AdventuresDigitalGallery} from "./components/website/pages/adventures-gallery/adventures-digital-gallery";
import {ScreenBusinesses} from "./components/website/pages/businesses/screen-businesses";
import ScreenForgotPassword from "./components/05_screens/authentication/forgot/screen-forgot-password";
import ScreenResetPassword from "./components/05_screens/authentication/reset/screen-reset-password";
import {ScreenRiddlesGallery} from "./components/admin/pages/riddles-gallery/screen-riddles-gallery";
import {RiddleMain} from "./components/admin/riddle-main/riddle-main";
import {SingleRiddle} from "./components/website/pages/riddles-store/single-riddle";


const YOUDO_LOGO = "https://storage.googleapis.com/assets.youdoadventures.com/website/youdo_logo.png";

class Route {
    constructor(title, description, image, path, component) {
        this.title = title;
        this.description = description;
        this.image = image;
        this.path = path;
        this.component = component;
    }
}

export const authenticationRoutes = [
    new Route("Youdo Adventures - Registration", "Registration", "", "/:lang/register", ScreenRegister),
    new Route("Youdo Adventures - Login", "Login", "", "/:lang/login", ScreenLogin),
    new Route("Youdo Adventures - Forgot password", "Forgot password", "", "/:lang/forgot-password", ScreenForgotPassword),
    new Route("Youdo Adventures - Reset password", "Reset password", "", "/:lang/reset-password", ScreenResetPassword),
];

export const websiteRoutes = [
    new Route("Youdo Adventures - About Us", "About Us", YOUDO_LOGO, '/:lang/about-us', ScreenAbout),
    new Route("Youdo Adventures - Product", "About Us", YOUDO_LOGO, '/:lang/product/:id', ScreenProduct),
    new Route("Youdo Adventures - Contact Us", "Contact Us", YOUDO_LOGO, '/:lang/contact-us', ScreenContactUs),
    new Route("Youdo Adventures - Gallery", "Gallery", YOUDO_LOGO, '/:lang/gallery', ScreenGallery),
    new Route("Youdo Adventures - Hall of Fame", "Hall of Fame", YOUDO_LOGO, '/:lang/hall-of-fame/:id?', ScreenGallery),
    new Route("Youdo Adventures - Terms & Conditions", "Terms & Conditions", YOUDO_LOGO, '/:lang/terms', ScreenTerms),
    new Route("Youdo Adventures - User", "User", YOUDO_LOGO, '/:lang/user', ScreenUser),
    new Route("Youdo Adventures - Riddles Store", "Riddles Store", YOUDO_LOGO, '/:lang/riddles-store', RiddleStore),
    new Route("Youdo Adventures - Riddle", "Riddle", YOUDO_LOGO, '/:lang/single-riddle/:id', SingleRiddle),
    new Route("Youdo Adventures - Blog", "Blog", YOUDO_LOGO, '/:lang/blogs', ScreenBlog),
    new Route("Youdo Adventures - Single Blog", "Single Blog", YOUDO_LOGO, '/:lang/blog/:id', SingleBlog),
    new Route("Youdo Adventures - Businesses", "Businesses", YOUDO_LOGO, '/:lang/businesses', ScreenBusinesses),

    new Route("Youdo Adventures - Adventure", "Adventure", YOUDO_LOGO, '/:lang/adventure/:id', ScreenAdventure),
    new Route("Youdo Adventures - Customize adventures gallery", "Adventure", YOUDO_LOGO, '/:lang/customize-adventures', AdventuresCustomizeGallery),
    new Route("Youdo Adventures - Digital adventures gallery", "Adventure", YOUDO_LOGO, '/:lang/digital-adventures', AdventuresDigitalGallery),
    new Route("Youdo Adventures - Payment", "Payment", YOUDO_LOGO, '/:lang/adventure-payment/:id', ScreenPayment),
    new Route("Youdo Adventures - Payment Success", "Payment Success", YOUDO_LOGO, '/:lang/payment/success', PaymentSuccess),
    new Route("Youdo Adventures - Payment Fail", "Payment Fail", YOUDO_LOGO, '/:lang/payment/fail', PaymentFail),
    new Route("Youdo Adventures - Create Adventure", "Create Adventure", YOUDO_LOGO, '/:lang/create-adventure/:id/:level?/:role?', ScreenAdventureCreate),
    new Route("Youdo Adventures - Space Adventure", "Space Adventure", YOUDO_LOGO, '/:lang/space-adventure/:adventureId?', CreateSpaceAdventure),
    new Route("Youdo Adventures - FAQ", "FAQ", YOUDO_LOGO, '/:lang/faq', ScreenFaq)
];


export const adminRoutes = [
    new Route("Youdo Admin - Dashboard", "Admin", "", "/:lang/admin/dashboard", ScreenDashboard),
    new Route("Youdo Admin - reservations", "reservations", "", "/:lang/admin/reservations", ScreenAdventuresGallery),
    new Route("Youdo Admin - reservation", "reservation", "", "/:lang/admin/reservation/:id/:activeTab?/:activeFrame?", SingleAdventure),
    new Route("Youdo Admin - new reservation", "reservation", "", "/:lang/admin/new-reservation", NewAdventure),
    new Route("Youdo Admin - adventures", "adventures", "", "/:lang/admin/adventures", ScreenAdventureTypeGallery),
    new Route("Youdo Admin - adventure", "adventure", "", "/:lang/admin/adventure/:id/:activeTab?", CreateAdventureType),
    new Route("Youdo Admin - Templates", "Templates", "", "/:lang/admin/templates", ScreenTemplatesGallery),
    new Route("Youdo Admin - Adventure types", "Adventure", "", "/:lang/admin/adventure-types", ScreenAdventureTemplatesGallery),
    new Route("Youdo Admin - Translations", "Translations", "", "/:lang/admin/translations", ScreenTranslations),
    new Route("Youdo Admin - Categories", "Categories", "", "/:lang/admin/categories/:toolName?", ScreenCategoriesGallery),
    new Route("Youdo Admin - Riddles", "Riddles", "", "/:lang/admin/riddles", ScreenRiddlesGallery),
    new Route("Youdo Admin - Riddles", "Riddles", "", "/:lang/admin/riddle/:riddleId", RiddleMain),
    new Route("Youdo Admin - Themes", "Themes", "", "/:lang/admin/themes", ScreenThemesGallery),
    new Route("Youdo Admin - Tags", "Tags", "", "/:lang/admin/tags", ScreenTagsGallery),
    new Route("Youdo Admin - Coupons", "Coupons", "", "/:lang/admin/coupons", ScreenCouponsGallery),
    new Route("Youdo Admin - customers", "Customers", "", "/:lang/admin/customers", ScreenUsersGallery),
    new Route("Youdo Admin - Blog", "Blog", "", "/:lang/admin/blog/:blogId?", ScreenBlogEdit),
    new Route("Youdo Admin - Blog", "Blog", "", "/:lang/admin/blogs", ScreenAdminBlog),
];