import React, {useEffect, useState} from "react";
import {Col, Row} from "react-grid-system";
import {FieldCheckbox, FieldColor, FieldText, FieldTextArea} from "../../../../../02_molecules";
import {MdArrowDropDown, MdArrowDropUp} from "react-icons/all";

export const AdditionalDataTextAfter = (
    {handleToolDataChange, textAfterSize = 18, textAfterColor = "#000", textAfter}
) => {
    const [isActive, setIsActive] = useState(textAfter);
    const [isOpen, setIsOpen] = useState(textAfter);

    useEffect(() => setIsOpen(isActive), [isActive]);

    const toggle = (name, value) => {
        setIsActive(value);
        if (!value) {
            handleToolDataChange("textAfter", "");
        }
    };
    const IconDrop = isOpen ? MdArrowDropUp : MdArrowDropDown;
    const wrapperStyle = {borderBottom: `${isOpen ? 1 : 0}px dashed #000`, marginBottom: "10px"}

    return (
        <div style={wrapperStyle}>
            <div className={"d-flex justify-content-between align-items-center"}>
                <FieldCheckbox options={["טקסט מתחת הכלי"]}
                               name="textActive"
                               controlStyle={{marginBottom: 0}}
                               value={isActive ? ["טקסט מתחת הכלי"] : []}
                               changeHandler={toggle}/>
                {isActive && <IconDrop onClick={() => setIsOpen(!isOpen)} cursor={"pointer"} size={20}/>}
            </div>

            {isActive && isOpen &&
            <Row className={"pt-3 px-3 pb-0"}>
                <Col xs={12}>
                    <FieldTextArea changeHandler={handleToolDataChange}
                                   wysiwyg={true}
                                   toolbarHidden={false}
                                   name="textAfter"
                                   rows={2}
                                   value={textAfter}/>
                </Col>
            </Row>}
        </div>
    )
}