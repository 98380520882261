import React, {useRef, useState} from "react";
import {applyMiddleware, createStore} from 'redux';
import {setConfiguration, useScreenClass} from 'react-grid-system';

import ReduxThunk from 'redux-thunk'
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from "react-router-dom";
import '@fortawesome/fontawesome-svg-core/styles.css'
import {config, library} from '@fortawesome/fontawesome-svg-core'
import {faBars, faTimes, faUser} from '@fortawesome/free-solid-svg-icons'
import {faFacebook, faTwitter} from '@fortawesome/free-brands-svg-icons'
import adventureReducer from "./store/reducers/adventure-reducers";
import {breakpoints, containerWidths, gridColumns, gutterWidth,} from './components/00_base/configurations';
import {AppContent} from "./components/04_layout";
import {LanguageProvider} from "./providers/language-provider";
import {AuthProvider} from "./providers/auth-provider";
import "./app.scss";
import ScrollToTop from "./components/04_layout/scroll-to-top/l-scroll-to-top";
import {ThemeProvider} from "./providers/theme-provider";
import {Circle, Video} from "./components/01_atoms";
import {MdPlayCircleFilled} from "react-icons/all";
import {Modal} from "./components/03_organisms";
import {colors} from "./components/00_base/variables";

config.autoAddCss = false;
library.add(faBars, faUser, faTimes, faTwitter, faFacebook);

const store = createStore(adventureReducer, applyMiddleware(ReduxThunk));

setConfiguration({
    defaultScreenClass: 'sm',
    maxScreenClass: 'lg',
    breakpoints: breakpoints,
    containerWidths: containerWidths,
    gutterWidth: gutterWidth,
    gridColumns: gridColumns,
});

export const addClassNameToRoot = (className) => {
    document.getElementById("root").setAttribute("class", className);
};
const YOUDO_VIDEO = "https://storage.googleapis.com/assets.youdoadventures.com/website/youdo.mp4";
const App = () => {
    const elementRef = useRef(null);
    const screenClass = useScreenClass(elementRef);
    const wrapperClassName = `app-wrapper screen-${screenClass}`;
    const [mainVideoActive, setMainVideoActive] = useState(false);

    return (
        <Provider store={store}>
            <Router>
                <LanguageProvider>
                    <AuthProvider>
                        <ScrollToTop>
                            <div id={'app-wrapper'} className={wrapperClassName}>
                                <AppContent/>
                                {mainVideoActive &&
                                <Modal isActive={"active"}
                                       hideTrigger={true}
                                       contentStyle={{width:"1200px"}}
                                       closePreActions={() => setMainVideoActive(false)}>

                                    <Video source={YOUDO_VIDEO}
                                           autoPlay={true}
                                           loop={true}
                                           videoStyle={{position:"static", display:"block", transform:"none"}}/>

                                </Modal>}
                                <div className={"position-fixed d-none d-md-block"} style={videoTrigger}
                                     onClick={() => setMainVideoActive(true)}>
                                    <MdPlayCircleFilled size={50} color={colors.info}/>
                                </div>
                            </div>
                        </ScrollToTop>
                    </AuthProvider>
                </LanguageProvider>
            </Router>
        </Provider>
    )
};

const videoTrigger = {
    position: "fixed",
    bottom: "30px",
    left: "30px",
    zIndex: 2,
    cursor: "pointer"
}


export default App;