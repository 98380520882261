import React, {useState} from "react";
import {MdClose} from "react-icons/md";
import {FieldColor, FieldText, FilesUploader} from "../../../02_molecules";
import {Td, Tr} from "../../../03_organisms/table";
import {ToggleIcon} from "../../../03_organisms";
import {Label} from "../../../01_atoms";

export const CreateToolRotariesItem = (
    {
        id,
        initialRotateValue,
        answer,
        rotarySize,
        backgroundColor,
        dotCustomImage,
        isCustomDotStyle,
        rotaryColor,
        rotaryLabel,
        rotaryLabelColor,
        rotaryLabelSize = 18,
        backgroundImage,
        dotColor,
        color,
        index,
        removeItem,
        handleChange
    }) => {

    const [isOpen, setIsOpen] = useState(false);

    const changeHandler = (name, value) => handleChange(name, value, index);

    const fieldProps = {
        changeHandler: changeHandler,
        controlStyle: {marginBottom: 0},
        inputStyle: {maxWidth: "50px"}
    };

    return (
        <>
            <Tr key={id} className={"create-rotaries-item"}>
                <Td>
                    <div style={{textAlign: "center"}}>{index + 1}</div>
                </Td>
                {/*<Td>*/}
                {/*    <FieldText name="initialRotateValue"*/}
                {/*               type="number"*/}
                {/*               value={initialRotateValue}*/}
                {/*               {...fieldProps}/>*/}
                {/*</Td>*/}
                <Td>
                    <FieldText name="answer"
                               type="number"
                               value={answer}
                               {...fieldProps}/>
                </Td>

                <Td>
                    <FieldColor name="rotaryColor"
                                value={rotaryColor}
                                dir="vertical"
                                hideTextInput={true}
                                {...fieldProps} />
                </Td>
                <Td>
                    <FieldColor name="color"
                                value={color}
                                dir="vertical"
                                hideTextInput={true}
                                {...fieldProps} />
                </Td>
                <Td>
                    <FieldColor name="backgroundColor"
                                value={backgroundColor}
                                dir="vertical"
                                hideTextInput={true}
                                {...fieldProps} />
                </Td>
                <Td>
                    <FieldColor name="dotColor"
                                value={dotColor}
                                dir="vertical"
                                hideTextInput={true}
                                {...fieldProps} />
                </Td>
                <Td>
                    <div className="d-flex">
                        <ToggleIcon toggle={() => setIsOpen(!isOpen)} isOpen={isOpen}/>
                        <MdClose cursor="pointer" onClick={() => removeItem(id)}/>
                    </div>
                </Td>
            </Tr>
            {isOpen &&
            <Tr>
                <Td></Td>
                <Td>
                    <FilesUploader name="backgroundImage"
                                   value={backgroundImage}
                                   multiple={false}
                                   previewWidth="100px"
                                   previewHeight="100px"
                                   label="רקע"
                                   triggerValue="תמונת רקע"
                                   // triggerStyle={{width: "100px"}}
                                   {...fieldProps} />
                </Td>

                {isCustomDotStyle &&
                <>
                    <Td colSpan={3} style={{verticalAlign:"top", padding: "0 5px"}}>
                        <Label style={{marginBottom:".8rem", fontSize:"2rem"}}>תמונת מחוג</Label>
                        <FilesUploader name="dotCustomImage"
                                       value={dotCustomImage}
                                       label="מחוג"
                                       multiple={false}
                                       previewWidth="100px"
                                       previewHeight="100px"
                                       {...fieldProps}
                                       // triggerStyle={{width: "100px"}}
                                       controlStyle={{marginBottom:"10px"}}/>
                        <FieldText name="rotarySize"
                                   type="number"
                                   placeholder={"גודל מחוג"}
                                   value={rotarySize}
                                   {...fieldProps}
                                   inputStyle={{width:"100%"}}/>
                    </Td>
                </>
                }

                <Td colSpan={3} style={{verticalAlign:"top", padding: "0 5px"}}>
                    <FieldText name="rotaryLabel"
                               value={rotaryLabel}
                               placeholder={"העלה טקסט"}
                               label="טקסט מתחת לבורר"
                               changeHandler={changeHandler}/>
                    <FieldText type="number"
                               name="rotaryLabelSize"
                               value={rotaryLabelSize}
                               placeholder="גודל טקסט"
                               changeHandler={changeHandler}/>
                    <FieldColor name={"rotaryLabelColor"}
                                value={rotaryLabelColor || "#000000"}
                                placeholder="צבע טקסט"
                                changeHandler={changeHandler}/>
                </Td>
            </Tr>
            }
        </>
    )
};