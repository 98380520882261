import React, {useEffect, useState} from 'react';
import _ from "lodash";
import arrayMove from "array-move";
import {Element} from 'react-stylesheet';
import {StyledDiv} from "../../ui/css-general/css-styled";
import {BLOCKS} from "./create-gallery-consts";
import {CreateToolGalleryImagesSection} from "./create-tool-gallery-images-section";
import {CssGeneral} from "../../ui/css-general/css-general";
import {createNewId} from "../../../00_base/helpers/create-new-id";
import {Button} from '../../../01_atoms';
import {FrameUiPicker, SortableList} from "../../../03_organisms";
import "./create-tool-gallery-images.scss";
import {MdPlayArrow} from "react-icons/all";
import {FieldColor} from "../../../02_molecules";

const initialSection = {
    id: 0,
    icon: "",
    items: []
};

const CreateToolGalleryImages = (
    {
        items = [],
        item_style = {},
        gallery_view = BLOCKS,
        handleToolDataChange,
        indicatorStyle,
        arrowStyle,
        arrowColor,
    }) => {
    const [galleryItems, setGalleryItems] = useState(items);
    const [galleryView, setGalleryView] = useState(gallery_view);
    const [itemStyle, setItemStyle] = useState(item_style);

    useEffect(() => {
        if (!_.isEqual(items, galleryItems)) {
            setGalleryItems(items);
        }
    }, [items]);

    useEffect(() => {
        if (!_.isEqual(galleryItems, items)) {
            handleToolDataChange("items", galleryItems)
        }
    }, [handleToolDataChange, galleryItems, items]);

    useEffect(() => {
        if (!_.isEqual(galleryView, gallery_view)) {
            handleToolDataChange("gallery_view", galleryView);
        }
    }, [handleToolDataChange, galleryView, gallery_view]);

    useEffect(() => {
        if (!_.isEqual(item_style, itemStyle)) {
            handleToolDataChange("item_style", itemStyle);
        }
    }, [handleToolDataChange, itemStyle, item_style]);

    const addSection = () => {
        const newId = createNewId(galleryItems);
        setGalleryItems(galleryItems.concat({...initialSection, id: galleryItems.length > 0 ? newId : 0}));
    };

    const removeSection = (itemId) => {
        if (window.confirm("למחוק את הגלריה?")) {
            setGalleryItems(galleryItems.filter(item => item.id !== itemId));
        }
    };

    const handleSectionChange = (name, value, id) => {
        const current = galleryItems.find(item => item.id === id);
        current[name] = value;
        setGalleryItems(galleryItems);
        handleToolDataChange("items", galleryItems)
    };

    const renderSection = (gallery, index) => {
        const remove = () => removeSection(gallery.id);
        return <CreateToolGalleryImagesSection key={index}
                                               {...gallery}
                                               galleryView={galleryView}
                                               index={index}
                                               handleToolDataChange={handleToolDataChange}
                                               handleSectionChange={handleSectionChange}
                                               remove={remove}/>
    };

    const handleIndicatorStyleChange = (name, value) => {
        handleToolDataChange("indicatorStyle", {...indicatorStyle, [name]: value});
    };
    const handleArrowStyleChange = (name, value) => {
        handleToolDataChange("arrowStyle", {...arrowStyle, [name]: value});
    };
    const IndicatorPreviewComponent = () => {
        return (
            <StyledDiv {...indicatorStyle} className={"slider-create-preview-indicator"}
                       style={{display: "flex", justifyContent: "center", alignItems: "center"}}>1</StyledDiv>
        )
    };

    const ArrowPreviewComponent = () => {
        return (
            <StyledDiv {...arrowStyle} className={"slider-create-preview-indicator"}
                       style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <MdPlayArrow color={arrowColor}/>
            </StyledDiv>
        )
    };

    const reorderGalleries = ({oldIndex, newIndex}) => {
        const updatedItems = arrayMove(galleryItems, oldIndex, newIndex);
        setGalleryItems(updatedItems);
        handleToolDataChange("items", updatedItems)
    };

    return (
        <div className="create-tool-gallery">
            <div className="gallery-details">
                <div className="gallery-details-fields">
                    <Element marginRight="auto">
                        <FrameUiPicker value={galleryView}
                                       name="gallery_view"
                                       changeHandler={(name, value) => setGalleryView(value)}/>
                    </Element>
                </div>

                <SortableList items={galleryItems.map(renderSection)}
                              useDragHandle={true}
                              onSortEnd={reorderGalleries}/>

            </div>

            <div className="gallery-add">
                <Button block
                        onClick={addSection}
                        value='הוסף גלריה'/>
            </div>

            <CssGeneral title="עיצוב תמונה"
                        defaultProps={itemStyle}
                        changeHandler={(name, value) => setItemStyle({...itemStyle, [name]: value})}/>

            {galleryView === "carrousel" &&
            <>
                <CssGeneral key={"gallery-slider-indicator-ui"}
                            className="gallery-slider-indicator-ui"
                            title="סליידר - עיצוב כפתור אינדיקציה"
                            changeHandler={handleIndicatorStyleChange}
                            PreviewComponent={IndicatorPreviewComponent}
                            defaultProps={indicatorStyle}/>

                <FieldColor changeHandler={handleToolDataChange}
                           label={"צבע חצים"}
                           name={"arrowColor"}
                            controlStyle={{margin:"10px 0 0"}}
                            labelStyle={{margin:0}}
                           value={arrowColor}/>

                <CssGeneral key={"gallery-slider-arrow-ui"}
                            className="gallery-slider-arrow-ui"
                            title="סליידר - עיצוב חצים"
                            changeHandler={handleArrowStyleChange}
                            PreviewComponent={ArrowPreviewComponent}
                            defaultProps={arrowStyle}/>
            </>
            }

        </div>
    )
};

CreateToolGalleryImages.defaultProps = {};

CreateToolGalleryImages.propTypes = {};


export default CreateToolGalleryImages;