import React, {useEffect, useState} from "react";
import {MdAddCircle, MdClose} from 'react-icons/md';
import _ from "lodash";
import {FieldText, FilesUploader} from "../../../02_molecules";
import {Table, Td, Tr} from "../../../03_organisms/table";
import {ColorPickerBgText} from "../../../03_organisms/color-picker-bg-text/color-picker-bg-text";
import {ToggleIcon} from "../../../03_organisms";

export const CreateToolTriviaSection = (
    {
        id,
        index,
        color,
        bgColor,
        textColor,
        questionLabel,
        removeSection,
        question,
        icon,
        options = ["", "", "", ""],
        handleChange,
        answer,
    }
) => {
    const [isOpen, setIsOpen] = useState(true);
    const [checkedAnswer, setCheckedAnswer] = useState(answer);

    const changeHandler = (name, value) => handleChange(name, value, id);

    useEffect(() => {
        if (!_.isEqual(answer, checkedAnswer)) {
            handleChange("answer", checkedAnswer, id);
        }
    }, [handleChange, checkedAnswer, answer, id]);

    const addOption = () => {
        changeHandler("options", options.concat([""]));
    };
    return (
        <Tr>
            <Td className="trivia-create-section">
                <Table>
                    <tbody>
                    <Tr>
                        <Td style={{padding:0}}>
                            <FilesUploader name="icon"
                                           value={icon}
                                           labelStyle={{marginBottom: 0, marginLeft: "15px"}}
                                           controlStyle={controlStyle}
                                           multiple={false}
                                           previewWidth={40}
                                           previewHeight={40}
                                           triggerStyle={{width:"60px", cursor:"pointer"}}
                                           triggerValue="איקון"
                                           index={index}
                                           inputStyle={{...inputStyle, width: "40px"}}
                                           changeHandler={changeHandler}/>
                        </Td>
                        <Td width="50px">
                            <FieldText value={questionLabel}
                                       labelStyle={{marginBottom: 0, marginLeft: "15px"}}
                                       controlStyle={controlStyle}
                                       placeholder={index + 1}
                                       index={index}
                                       inputStyle={{...inputStyle, width: "40px"}}
                                       name="questionLabel"
                                       changeHandler={changeHandler}/>
                        </Td>
                        <Td>
                            <FieldText name="question"
                                       value={question}
                                       placeholder='הכנס שאלה'
                                       index={index}
                                       controlStyle={controlStyle}
                                       inputStyle={inputStyle}
                                       changeHandler={changeHandler}/>
                        </Td>

                        <Td style={{width: "30px"}}>
                            <ColorPickerBgText bgColor={bgColor || color}
                                               textColor={textColor}
                                               handleChange={changeHandler}/>
                        </Td>
                        <Td className="trivia-create-section-remove">
                            <MdClose onClick={() => removeSection(id)}/>
                            {/*<ToggleIcon toggle={() => setIsOpen(!isOpen)} isOpen={isOpen}/>*/}
                        </Td>
                    </Tr>
                    </tbody>
                </Table>
                {isOpen &&
                <Table>
                    <tbody>
                    <Tr>
                        {options.map((option, o) => {
                            const changeHandler = (name, value) => {
                                handleChange("options", options.map((opt, i) => i === o ? value : opt), id);
                            };
                            const removeOption = () => {
                                handleChange("options", options.filter((opt, i) => i !== o), id);
                            };
                            const removeIcon = <MdClose onClick={removeOption}
                                                        className="ml-2"
                                                        cursor="pointer"/>;
                            return (
                                <Td key={o}>
                                    <div className="trivia-create-section-answer">
                                        <input type={"radio"}
                                               name={`answer_${id}_${o}`}
                                               checked={checkedAnswer === o}
                                               style={radioStyle}
                                               onChange={(name, value) => setCheckedAnswer(o)}/>
                                        <FieldText key={o}
                                                   placeholder={`הכנס תשובה`}
                                                   value={option}
                                                   index={o}
                                                   sideIcon={removeIcon}
                                                   controlStyle={{...controlStyle}}
                                                   inputStyle={{...inputStyle, width: "100%"}}
                                                   name={`option_${id}_${o}`}
                                                   changeHandler={changeHandler}/>
                                    </div>
                                </Td>
                            )
                        })}
                        <Td>
                            <MdAddCircle cursor="pointer"
                                         onClick={addOption}/>
                        </Td>
                    </Tr>
                    </tbody>
                </Table>}
            </Td>
        </Tr>
    )
};
const controlStyle = {
    margin: 0,
    paddingLeft: "5px",
    paddingRight: "5px"
};
const inputStyle = {
    height: '3rem'
};
const radioStyle = {
    width: "30px",
    cursor: "pointer",
    marginLeft: 0,
    paddingRight: "10px",
    marginBottom: 0
};